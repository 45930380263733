import React from 'react'
import {
  SimpleForm,
  Create,
  Edit,
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  ReferenceInput,
  required,
  AutocompleteInput,
  ReferenceField, TextInput, DateField
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    {DateFilters}
  </Filter>
)

export const ChurnsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        source='clientId'
        reference='clients'
        sort={{
          field: 'email',
          order: 'ASC'
        }}
        filterToQuery={searchText => {
          return searchText && {
            'email[$regex]': searchText,
            'email[$options]': 'i'
          }
        }}
        allowEmpty
        validate={required()}
      >
        <AutocompleteInput
          optionText='email'
          emptyText='clear search'
        />
      </ReferenceInput>
      <TextInput
        source='reason'
        type='reason'
        validate={required()}
      />
      <TextInput
        source='otherReason'
        type='otherReason'
        // validate={required()}
      />
      <TextInput
        source='subscriptionPlanId'
        type='subscriptionPlanId'
        validate={required()}
      />
    </SimpleForm>
  </Create>
)

export const ChurnsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField
        source='_id'
        label='Id'
      />
      <ReferenceInput
        source='clientId'
        reference='clients'
        sort={{
          field: 'email',
          order: 'ASC'
        }}
        filterToQuery={searchText => {
          return searchText && {
            $regex: searchText,
            $options: 'i'
          }
        }}
        allowEmpty
        validate={required()}
      >
        <AutocompleteInput
          optionText='email'
          emptyText='clear search'
        />
      </ReferenceInput>
      <TextInput
        source='reason'
        type='reason'
        validate={required()}
      />
      <TextInput
        source='otherReason'
        type='otherReason'
      />
      <TextInput
        source='subscriptionPlanId'
        type='subscriptionPlanId'
        validate={required()}
      />
      <DateField
        source='createdAt'
        label='Created at'
        showTime
      />
      <DateField
        source='updatedAt'
        label='Updated at'
        showTime
      />
    </SimpleForm>
  </Edit>
)

export const ChurnsList = (props) => (
  <List
    {...props}
    filters={<Filters />}
  >
    <Datagrid>
      <ReferenceField source="clientId" reference="clients">
        <TextField
          source='email'
        />
      </ReferenceField>
      <TextField
        source='reason'
      />
      <TextField
        source='otherReason'
      />
      <TextField
        source='subscriptionPlanId'
      />
      <DateField
        source='createdAt'
        label='Created at'
        showTime
      />
      <EditButton />
    </Datagrid>
  </List>
)
