import React from 'react'
import {
  SimpleForm,
  Create,
  TextInput,
  TabbedForm,
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  FormTab,
  required,
  ReferenceManyField,
  Edit,
  Pagination,
  DateField,
  AutocompleteInput,
  ReferenceInput,
  NumberField
} from 'react-admin'
import { Field } from 'react-final-form'
import DateFilters from '../../elements/DateFilters'
import RichTextInput from 'ra-input-rich-text'
import useCloudinary from '../../hooks/useCloudinary'
import ImageInput from '../../elements/ImageInput'

export const Filters = (props) => (
  <Filter {...props}>
    {DateFilters}
  </Filter>
)

export const ProgramsCreate = (props) => {
  const { widget, source } = useCloudinary()

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source='name'
          type='name'
          validate={required()}
        />
        <TextInput
          style={{ width: '100%' }}
          source='descriptionTitle'
          validate={required()}
        />
        <RichTextInput
          source='description'
          validate={required()}
        />
        <TextInput
          source='slogan'
          validate={required()}
        />
        <Field name='image'>
          {formProps =>
            <ImageInput
              formProps={formProps}
              widget={widget}
              source={source}
            />
          }
        </Field>
        <ReferenceInput
          source='levelId'
          reference='levels'
          sort={{
            field: 'name',
            order: 'ASC'
          }}
          filterToQuery={searchText => {
            return searchText && {
              'name[$regex]': searchText,
              'name[$options]': 'i'
            }
          }}
          allowEmpty
        >
          <AutocompleteInput
            optionText='name'
            emptyText='clear search'
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export const ProgramsEdit = (props) => {
  const { widget, source } = useCloudinary()

  return (
    <Edit {...props}>
      <TabbedForm
        redirect={false}
      >
        <FormTab label='summary'>
          <TextField
            source='_id'
            label='Id'
          />
          <TextInput
            source='name'
            type='text'
            validate={required()}
          />
          <TextInput
            source='descriptionTitle'
            validate={required()}
            style={{ width: '100%' }}
          />
          <RichTextInput
            source='description'
            validate={required()}
          />
          <TextInput
            source='slogan'
            validate={required()}
          />
          <Field name='image'>
            {formProps =>
              <ImageInput
                formProps={formProps}
                widget={widget}
                source={source}
              />
            }
          </Field>
          <ReferenceInput
            source='levelId'
            reference='levels'
            sort={{
              field: 'name',
              order: 'ASC'
            }}
            filterToQuery={searchText => {
              return searchText && {
                'name[$regex]': searchText,
                'name[$options]': 'i'
              }
            }}
            allowEmpty
          >
            <AutocompleteInput
              optionText='name'
              emptyText='clear search'
            />
          </ReferenceInput>
          <DateField
            source='createdAt'
            label='Created at'
            showTime
          />
          <DateField
            source='updatedAt'
            label='Updated at'
            showTime
          />
        </FormTab>
        <FormTab label='videos'>
          <ReferenceManyField
            addLabel={false}
            fullWidth
            reference='videos'
            target='programId'
            perPage={10}
            pagination={<Pagination/>}
            sort={{
              field: 'createdAt',
              order: 'DESC'
            }}
          >
            <Datagrid>
              <TextField source='_id'/>
              <TextField
                source='name'
              />
              <NumberField
                source='order'
              />
              <EditButton/>
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const ProgramsList = (props) => (
  <List
    {...props}
    filters={<Filters/>}
  >
    <Datagrid>
      <TextField
        source='name'
      />
      <EditButton/>
    </Datagrid>
  </List>
)
