import React from 'react'
import {
  SimpleForm,
  Create,
  TextInput,
  Edit,
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  SearchInput,
  required
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    <SearchInput
      label='Email'
      source='email'
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

export const AdminCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='email'
        type='email'
        validate={required()}
      />
      <TextInput
        source='password'
        type='password'
        validate={required()}
      />
    </SimpleForm>
  </Create>
)

export const AdminEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput
        source='email'
        type='text'
        validate={required()}
      />
      <TextInput
        source='password'
        type='password'
        validate={required()}
      />
    </SimpleForm>
  </Edit>
)

export const AdminList = (props) => (
  <List
    {...props}
    filters={<Filters />}
  >
    <Datagrid>
      <TextField
        source='email'
      />
      <TextField
        source='_id'
      />
      <EditButton />
    </Datagrid>
  </List>
)
