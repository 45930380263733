import React from 'react'
import {
  SimpleForm,
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  Edit,
  DateField,
  BooleanInput,
  BooleanField
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    {DateFilters}
  </Filter>
)

export const ContactFormEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField
        source='_id'
        label='Id'
      />
      <TextField
        source='author'
      />
      <TextField
        source='email'
      />
      <TextField
        source='topic'
      />
      <TextField
        source='content'
      />
      <BooleanInput
        source='done'
        label='Przeczytane'
      />
      <DateField
        source='createdAt'
        label='Created at'
        showTime
      />
      <DateField
        source='updatedAt'
        label='Updated at'
        showTime
      />
    </SimpleForm>
  </Edit>
)

export const ContactFormList = (props) => (
  <List
    {...props}
    filters={<Filters/>}
  >
    <Datagrid>
      <TextField
        source='author'
      />
      <TextField
        source='email'
      />
      <TextField
        source='topic'
      />
      <BooleanField
        source='done'
      />
      <EditButton/>
    </Datagrid>
  </List>
)
