import React from 'react'
import {
  SimpleForm,
  Create,
  TextInput,
  TabbedForm,
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  FormTab,
  required,
  Edit,
  DateField
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    {DateFilters}
  </Filter>
)

export const FaqCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='title'
        type='title'
        validate={required()}
      />
      <TextInput
        source='content'
        style={{ width: '100%' }}
        type='text'
        validate={required()}
      />
    </SimpleForm>
  </Create>
)

export const FaqEdit = (props) => (
  <Edit {...props}>
    <TabbedForm
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField
          source='_id'
          label='Id'
        />
        <TextInput
          source='title'
          type='text'
          validate={required()}
        />
        <TextInput
          source='content'
          style={{ width: '100%' }}
          type='text'
          validate={required()}
        />
        <DateField
          source='createdAt'
          label='Created at'
          showTime
        />
        <DateField
          source='updatedAt'
          label='Updated at'
          showTime
        />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const FaqList = (props) => (
  <List
    {...props}
    filters={<Filters/>}
  >
    <Datagrid>
      <TextField
        source='title'
      />
      <EditButton/>
    </Datagrid>
  </List>
)
