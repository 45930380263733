import React, { useEffect } from 'react'
import { Button, IconButton } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Delete'

const ImageInput = ({ widget, formProps, source }) => {
  useEffect(() => {
    formProps.input.onChange(source?.url)
  }, [source])

  return (
    <div>
      {formProps.input.value
        ? (
          <>
            <img
              onClick={widget?.open}
              src={formProps.input.value}
              alt='img'
              width={300}
              height='auto'
            />
            <IconButton
              onClick={() => formProps.input.onChange(undefined)}
            >
              <RemoveIcon />
            </IconButton>
          </>
        )
        : (
          <Button
            color='primary'
            onClick={widget?.open}
          >
            {`Dodaj zdjęcie`}
          </Button>
        )}
    </div>
  )
}

export default ImageInput
