import React from 'react'
import {
  BooleanField,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  FormTab,
  List,
  required,
  SearchInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    <SearchInput
      label='Email'
      source={`email`}
      alwaysOn
    />
    <SearchInput
      label='Nazwisko'
      source={`lastName`}
      alwaysOn
    />
    <SearchInput
      label='Imię'
      source={`firstName`}
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

export const ClientCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='firstName'
        type='text'
        validate={required()}
      />
      <TextInput
        source='lastName'
        type='text'
        validate={required()}
      />
      <TextInput
        source='email'
        type='email'
        validate={required()}
      />
      <TextInput
        source='password'
        type='password'
        validate={required()}
      />
    </SimpleForm>
  </Create>
)

export const ClientEdit = (props) => (
  <Edit {...props}>
    <TabbedForm
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField
          source='_id'
          label='Id'
        />
        <TextInput
          source='firstName'
          type='text'
          validate={required()}
        />
        <TextInput
          source='lastName'
          type='text'
          validate={required()}
        />
        <TextInput
          source='email'
          type='text'
          validate={required()}
        />
        <TextInput
          source='password'
          type='password'
        />
        <TextField
          source='subscriptionPlanId'
        />
        <DateInput
          source='accessExpiryDate'
        />
        <TextField
          source='customerId'
        />
        <DateField
          source='createdAt'
          label='Created at'
          showTime
        />
        <DateField
          source='updatedAt'
          label='Updated at'
          showTime
        />
      </FormTab>
      <FormTab label='verify email'>
        <BooleanField label='Is Verified' source='isVerified'/>
        <TextField source='verifyShortToken'/>
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const ClientList = (props) => (
  <List
    {...props}
    filters={<Filters/>}
  >
    <Datagrid>
      <TextField
        source='firstName'
      />
      <TextField
        source='lastName'
      />
      <TextField
        source='email'
      />
      <DateField
        source='accessExpiryDate'
      />
      <EditButton/>
    </Datagrid>
  </List>
)
