import React from 'react'
import {
  SimpleForm,
  Create,
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  required,
  Edit,
  BooleanField,
  BooleanInput
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    {DateFilters}
  </Filter>
)

export const PrivacyPolicyCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput
        source='active'
      />
      <RichTextInput
        source='content'
        validate={required()}
      />
    </SimpleForm>
  </Create>
)

export const PrivacyPolicyEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput
        source='active'
      />
      <RichTextInput
        source='content'
        validate={required()}
      />
    </SimpleForm>
  </Edit>
)

export const PrivacyPolicyList = (props) => (
  <List
    {...props}
    filters={<Filters/>}
  >
    <Datagrid>
      <TextField
        source='_id'
      />
      <BooleanField
        source='active'
      />
      <EditButton/>
    </Datagrid>
  </List>
)
