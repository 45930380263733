import React from 'react'
import {
  SimpleForm,
  Create,
  Edit,
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  ReferenceInput,
  required,
  AutocompleteInput,
  ReferenceField
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    {DateFilters}
  </Filter>
)

export const FavoriteCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        source='videoId'
        reference='videos'
        sort={{
          field: 'name',
          order: 'ASC'
        }}
        filterToQuery={searchText => {
          return searchText && {
            'name[$regex]': searchText,
            'name[$options]': 'i'
          }
        }}
        allowEmpty
        validate={required()}
      >
        <AutocompleteInput
          optionText='name'
          emptyText='clear search'
        />
      </ReferenceInput>
      <ReferenceInput
        source='clientId'
        reference='clients'
        sort={{
          field: 'email',
          order: 'ASC'
        }}
        filterToQuery={searchText => {
          return searchText && {
            'email[$regex]': searchText,
            'email[$options]': 'i'
          }
        }}
        allowEmpty
        validate={required()}
      >
        <AutocompleteInput
          optionText='email'
          emptyText='clear search'
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const FavoriteEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput
        source='videoId'
        reference='videos'
        sort={{
          field: 'name',
          order: 'ASC'
        }}
        filterToQuery={searchText => {
          return searchText && {
            $regex: searchText,
            $options: 'i'
          }
        }}
        allowEmpty
        validate={required()}
      >
        <AutocompleteInput
          optionText='name'
          emptyText='clear search'
        />
      </ReferenceInput>
      <ReferenceInput
        source='clientId'
        reference='clients'
        sort={{
          field: 'email',
          order: 'ASC'
        }}
        filterToQuery={searchText => {
          return searchText && {
            $regex: searchText,
            $options: 'i'
          }
        }}
        allowEmpty
        validate={required()}
      >
        <AutocompleteInput
          optionText='email'
          emptyText='clear search'
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

export const FavoriteList = (props) => (
  <List
    {...props}
    filters={<Filters/>}
  >
    <Datagrid>
      <TextField
        source='_id'
      />
      <ReferenceField source="videoId" reference="videos">
        <TextField
          source='name'
        />
      </ReferenceField>
      <ReferenceField source="clientId" reference="clients">
        <TextField
          source='email'
        />
      </ReferenceField>
      <EditButton/>
    </Datagrid>
  </List>
)
