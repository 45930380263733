import React, {useCallback, useEffect, useState} from 'react'
import IconCancel from '@material-ui/icons/Cancel'
import IconSave from '@material-ui/icons/Save'
import { Dialog, DialogTitle, DialogContent, DialogActions, Select, Chip, MenuItem } from '@material-ui/core'
import {Button, useDataProvider} from 'react-admin'

const ChangeTargetsModal = (props) => {
  const dataProvider = useDataProvider()
  const [targetIds, setTargetIds] = useState([])
  const [targets, setTargets] = useState([])
  const [initialTargets, setInitialTargets] = useState([])

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await dataProvider.getList('videoTargets', {
          filter: {
            videoId: props.id
          }
        })

        res && res.data && setInitialTargets(res.data)
        res && res.data && setTargetIds(res.data.map(videoTarget => videoTarget.targetId))
        const targetsRes = await dataProvider.getList('targets', {
          filter: {
            $limit: 500
          }
        })
        targetsRes && setTargets(targetsRes.data)
      } catch (e) {
        console.log('e ===', e)
      }
    }
    getData()
  }, [dataProvider, props.id])

  const handleChange = useCallback((event) => {
    setTargetIds(event.target.value)
  }, [])

  const handleSaveClick = useCallback(async () => {
    try {
      const createTargets = targetIds.filter(id => !initialTargets.map(target => target.targetId).includes(id))
      const deleteTargets = initialTargets.filter(target => !targetIds.includes(target.targetId))
      await dataProvider.deleteMany('videoTargets', {
        ids: deleteTargets.map(target => target._id)
      })
      await Promise.all(createTargets.map(targetId => dataProvider.create('videoTargets', {
        data: {
          videoId: props.id,
          targetId
        }
      })))
    } catch (e) {
      console.log('error on changing targets ===', e)
    }
    props.handleClose()
  }, [targetIds, initialTargets, dataProvider, props.id, props.handleClose])

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={props.handleClose}
      aria-label='Zmień cele'
    >
      <DialogTitle>Zmień cele</DialogTitle>
      <DialogContent>
        <Select
          style={{ width: '100%' }}
          multiple
          value={targetIds}
          onChange={handleChange}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => {
                const target = targets.find(target => target._id === value)
                return (
                  <Chip key={value} label={target?.name || ''}/>
                )
              })}
            </div>
          )}
        >
          {targets.map(target =>
            <MenuItem key={target._id} value={target._id}>{target.name}</MenuItem>
          )}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button label='Save' onClick={handleSaveClick}>
          <IconSave/>
        </Button>
        <Button label='Cancel' onClick={props.handleClose}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeTargetsModal
