import React from 'react'
import {Admin, Resource} from 'react-admin'
import {authClient, restClient} from 'ra-data-feathers'
import feathersClient from './client/feathersClient'
import {AdminCreate, AdminEdit, AdminList} from './models/admins'
import {ClientCreate, ClientEdit, ClientList} from "./models/clients";
import {VideoCreate, VideoEdit, VideoList} from "./models/videos";
import {FavoriteCreate, FavoriteEdit, FavoriteList} from "./models/favorites";
import {PlannedCreate, PlannedEdit, PlannedList} from "./models/planned";
import {WatchedCreate, WatchedEdit, WatchedList} from "./models/watched";
import { TargetCreate, TargetEdit, TargetList } from './models/targets'
import {
  EquipmentsCreate,
  EquipmentsEdit,
  EquipmentsList,
} from './models/equipments'
import { LevelCreate, LevelEdit, LevelList } from './models/levels'
import {FaqCreate, FaqEdit, FaqList} from './models/faq'
import {VideoTargetCreate, VideoTargetEdit, VideoTargetList} from './models/videoTargets'
import {PrivacyPolicyCreate, PrivacyPolicyEdit, PrivacyPolicyList} from './models/privacyPolicy'
import {TermsAndConditionsCreate, TermsAndConditionsEdit, TermsAndConditionsList} from './models/termsAndConditions'
import {ProgramsCreate, ProgramsEdit, ProgramsList} from './models/programs'
import { ContactFormList, ContactFormEdit } from './models/contactForm'

import EmailsList from './models/emails/List'
import EmailsCreate from './models/emails/Create'
import EmailsEdit from './models/emails/Edit'
import {
  VideoPodcastsCreate,
  VideoPodcastsEdit,
  VideoPodcastsList
} from './models/videoPodcasts'
import { ChurnsCreate, ChurnsEdit, ChurnsList } from './models/churns'


const restClientOptions = {
  id: '_id', // In this example, the database uses '_id' rather than 'id'
  usePatch: true // Use PATCH instead of PUT for updates
}

const authClientOptions = {
  storageKey: 'feathers-jwt',
  authenticate: { // Options included in calls to Feathers client.authenticate
    strategy: 'local' // The authentication strategy Feathers should use
  },
  passwordField: 'password', // The key used to provide the password to Feathers client.authenticate
  usernameField: 'email', // Our example database might use 'username' rather than 'email'
  redirectTo: '/login' // Our example login form might be at '/login', redirect here if AUTH_CHECK fails
}

const App = () => {
  return (
    <Admin
      dataProvider={restClient(feathersClient, restClientOptions)}
      authProvider={authClient(feathersClient, authClientOptions)}
      locale='en'
    >
      <Resource
        name='admins'
        list={AdminList}
        create={AdminCreate}
        edit={AdminEdit}
      />
      <Resource
        name='clients'
        list={ClientList}
        create={ClientCreate}
        edit={ClientEdit}
      />
      <Resource
        name='videos'
        list={VideoList}
        create={VideoCreate}
        edit={VideoEdit}
      />
      <Resource
        name='favoriteVideos'
        options={{ label: 'Favorite Videos' }}
        list={FavoriteList}
        create={FavoriteCreate}
        edit={FavoriteEdit}
      />
      <Resource
        name='plannedVideos'
        options={{ label: 'Planned Videos' }}
        list={PlannedList}
        create={PlannedCreate}
        edit={PlannedEdit}
      />
      <Resource
        name='watchedVideos'
        options={{ label: 'Watched Videos' }}
        list={WatchedList}
        create={WatchedCreate}
        edit={WatchedEdit}
      />
      <Resource
        name='targets'
        list={TargetList}
        create={TargetCreate}
        edit={TargetEdit}
      />
      <Resource
        name='videoTargets'
        options={{ label: 'Video Targets' }}
        list={VideoTargetList}
        create={VideoTargetCreate}
        edit={VideoTargetEdit}
      />
      <Resource
        name='programs'
        list={ProgramsList}
        create={ProgramsCreate}
        edit={ProgramsEdit}
      />
      <Resource
        name='videoPodcasts'
        options={{ label: 'Video Podcasts' }}
        list={VideoPodcastsList}
        create={VideoPodcastsCreate}
        edit={VideoPodcastsEdit}
      />
      <Resource
        name='equipments'
        list={EquipmentsList}
        create={EquipmentsCreate}
        edit={EquipmentsEdit}
      />
      <Resource
        name='levels'
        list={LevelList}
        create={LevelCreate}
        edit={LevelEdit}
      />
      <Resource
        name='faqs'
        list={FaqList}
        create={FaqCreate}
        edit={FaqEdit}
      />
      <Resource
        name='privacyPolicies'
        options={{ label: 'Polityka prywatności' }}
        list={PrivacyPolicyList}
        create={PrivacyPolicyCreate}
        edit={PrivacyPolicyEdit}
      />
      <Resource
        name='termsAndConditions'
        options={{ label: 'Regulamin' }}
        list={TermsAndConditionsList}
        create={TermsAndConditionsCreate}
        edit={TermsAndConditionsEdit}
      />
      <Resource
        name='contactForm'
        options={{ label: 'Formularz kontaktowy' }}
        list={ContactFormList}
        edit={ContactFormEdit}
      />
      <Resource
        name='emails'
        list={EmailsList}
        create={EmailsCreate}
        edit={EmailsEdit}
      />
      <Resource
        name='churns'
        list={ChurnsList}
        create={ChurnsCreate}
        edit={ChurnsEdit}
      />
    </Admin>
  )
}

export default App;
