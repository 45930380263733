import React from 'react'
import {
  SimpleForm,
  Create,
  TextInput,
  TabbedForm,
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  FormTab,
  required,
  ReferenceManyField,
  Edit,
  Pagination,
  DateField
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    {DateFilters}
  </Filter>
)

export const TargetCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='name'
        type='name'
        validate={required()}
      />
    </SimpleForm>
  </Create>
)

export const TargetEdit = (props) => (
  <Edit {...props}>
    <TabbedForm
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField
          source='_id'
          label='Id'
        />
        <TextInput
          source='name'
          type='text'
          validate={required()}
        />
        <DateField
          source='createdAt'
          label='Created at'
          showTime
        />
        <DateField
          source='updatedAt'
          label='Updated at'
          showTime
        />
      </FormTab>
      <FormTab label='videos'>
        <ReferenceManyField
          addLabel={false}
          fullWidth
          reference='videos'
          target='targetId'
          perPage={10}
          pagination={<Pagination />}
          sort={{
            field: 'createdAt',
            order: 'DESC'
          }}
        >
          <Datagrid>
            <TextField source='_id' />
            <TextField
              source='name'
            />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const TargetList = (props) => (
  <List
    {...props}
    filters={<Filters/>}
  >
    <Datagrid>
      <TextField
        source='name'
      />
      <EditButton/>
    </Datagrid>
  </List>
)
