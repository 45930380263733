import React from 'react'
import {
  SimpleForm,
  Create,
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  Edit,
  ReferenceInput,
  AutocompleteInput, ReferenceField
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    {DateFilters}
  </Filter>
)

export const VideoTargetCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        source='videoId'
        reference='videos'
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        filterToQuery={searchText => {
          return searchText && {
            'name[$regex]': searchText,
            'name[$options]': 'i',
          }
        }}
        allowEmpty
      >
        <AutocompleteInput
          optionText='name'
          emptyText='clear search'
        />
      </ReferenceInput>
      <ReferenceInput
        source='targetId'
        reference='targets'
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        filterToQuery={searchText => {
          return searchText && {
            'name[$regex]': searchText,
            'name[$options]': 'i',
          }
        }}
        allowEmpty
      >
        <AutocompleteInput
          optionText='name'
          emptyText='clear search'
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const VideoTargetEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput
        source='videoId'
        reference='videos'
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        filterToQuery={searchText => {
          return searchText && {
            'name[$regex]': searchText,
            'name[$options]': 'i',
          }
        }}
        allowEmpty
      >
        <AutocompleteInput
          optionText='name'
          emptyText='clear search'
        />
      </ReferenceInput>
      <ReferenceInput
        source='targetId'
        reference='targets'
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        filterToQuery={searchText => {
          return searchText && {
            'name[$regex]': searchText,
            'name[$options]': 'i',
          }
        }}
        allowEmpty
      >
        <AutocompleteInput
          optionText='name'
          emptyText='clear search'
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

export const VideoTargetList = (props) => (
  <List
    {...props}
    filters={<Filters/>}
  >
    <Datagrid>

      <ReferenceField
        source="videoId"
        reference="videos"
      >
        <TextField
          source='name'
        />
      </ReferenceField>

      <ReferenceField
        source="targetId"
        reference="targets"
      >
        <TextField
          source='name'
        />
      </ReferenceField>
      <EditButton/>
    </Datagrid>
  </List>
)
