import { useEffect, useState } from 'react'

const useCloudinary = (initialSource, settings) => {
  const [widget, setWidget] = useState(null)
  const [source, setSource] = useState(initialSource)

  useEffect(() => {
    if (window.cloudinary) {
      setWidget(
        window.cloudinary.createUploadWidget({
            cloudName: process.env.REACT_APP_CLOUD_NAME,
            uploadPreset: process.env.REACT_APP_UPLOAD_PRESET,
            ...settings
          },
          (err, result) => {
            const baseUrl = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUD_NAME}/image/upload`
            if (!err && !settings?.multiple && result?.event === 'success') {
              if (result.info.coordinates) {
                const coords = result.info.coordinates.custom[0]
                setSource({
                  publicId: result.info.public_id,
                  params: `x_${coords[0]},y_${coords[1]},c_crop,w_${coords[2]},h_${coords[3]}`,
                  url: `${baseUrl}/x_${coords[0]},y_${coords[1]},c_crop,w_${coords[2]},h_${coords[3]}/${result.info.public_id}`
                })
              } else {
                setSource({
                  publicId: result.info.public_id,
                  params: '',
                  url: `${baseUrl}/${result.info.public_id}`
                })
              }
            }
            if (!err && settings?.multiple && result?.event === 'queues-end') {
              const images = result.info.files.map(file => ({
                params: '',
                url: `${baseUrl}/${file.uploadInfo.path}`,
                publicId: file.uploadInfo.public_id
              }))
              setSource([...source, ...images])
            }
            if (err) {
              console.log(err)
            }
          })
      )
    }
  }, [source])

  return {
    widget,
    source,
    setSource
  }
}

export default useCloudinary
