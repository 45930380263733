import React, { useState } from 'react'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  FormTab,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SearchInput,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  SingleFieldList,
  ReferenceManyField,
  ChipField,
  NumberInput
} from 'react-admin'
import { Button, Typography } from '@material-ui/core'
import RichTextInput from 'ra-input-rich-text'
import DateFilters from '../../elements/DateFilters'
import ChangeTargetsModal from './ChangeTargetsModal'

export const Filters = (props) => (
  <Filter {...props}>
    <SearchInput
      label='Name'
      source='name'
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

export const VideoCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='wisitaHashId'
        validate={required()}
      />
      <ReferenceInput
        source='equipmentId'
        reference='equipments'
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        filterToQuery={searchText => {
          return searchText && {
            'name[$regex]': searchText,
            'name[$options]': 'i',
          }
        }}
        allowEmpty
      >
        <AutocompleteInput
          optionText='name'
          emptyText='clear search'
        />
      </ReferenceInput>
      <ReferenceInput
        source='levelId'
        reference='levels'
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        filterToQuery={searchText => {
          return searchText && {
            'name[$regex]': searchText,
            'name[$options]': 'i',
          }
        }}
        allowEmpty
      >
        <AutocompleteInput
          optionText='name'
          emptyText='clear search'
        />
      </ReferenceInput>
      <ReferenceInput
        source='programId'
        reference='programs'
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        filterToQuery={searchText => {
          return searchText && {
            'name[$regex]': searchText,
            'name[$options]': 'i',
          }
        }}
        allowEmpty
      >
        <AutocompleteInput
          optionText='name'
          emptyText='clear search'
        />
      </ReferenceInput>
      <ReferenceInput
        source='videoPodcastId'
        reference='videoPodcasts'
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        filterToQuery={searchText => {
          return searchText && {
            'name[$regex]': searchText,
            'name[$options]': 'i',
          }
        }}
        allowEmpty
      >
        <AutocompleteInput
          optionText='name'
          emptyText='clear search'
        />
      </ReferenceInput>
      <NumberInput
        source='order'
      />
    </SimpleForm>
  </Create>
)

export const VideoEdit = (props) => {
  const [open, setOpen] = useState()

  return (
    <Edit {...props}>
      <TabbedForm
        redirect={false}
      >
        <FormTab label='summary'>
          <TextField
            source='_id'
            label='Id'
          />
          <TextInput
            source='name'
            type='text'
            validate={required()}
          />
          <SelectInput
            source="videoStatus"
            choices={[
              {id: 'draft', name: 'Draft'},
              {id: 'active', name: 'Active'},
              {id: 'inActive', name: 'Not Active'}
            ]}
          />
          <ReferenceInput
            source='programId'
            reference='programs'
            sort={{
              field: 'name',
              order: 'ASC'
            }}
            filterToQuery={searchText => {
              return searchText && {
                'name[$regex]': searchText,
                'name[$options]': 'i'
              }
            }}
            allowEmpty
          >
            <AutocompleteInput
              optionText='name'
              emptyText='clear search'
            />
          </ReferenceInput>
          <ReferenceInput
            source='videoPodcastId'
            reference='videoPodcasts'
            sort={{
              field: 'name',
              order: 'ASC',
            }}
            filterToQuery={searchText => {
              return searchText && {
                'name[$regex]': searchText,
                'name[$options]': 'i',
              }
            }}
            allowEmpty
          >
            <AutocompleteInput
              optionText='name'
              emptyText='clear search'
            />
          </ReferenceInput>
          <NumberInput
            source='order'
          />
          <ReferenceInput
            source='equipmentId'
            reference='equipments'
            sort={{
              field: 'name',
              order: 'ASC'
            }}
            filterToQuery={searchText => {
              return searchText && {
                'name[$regex]': searchText,
                'name[$options]': 'i'
              }
            }}
            allowEmpty
          >
            <AutocompleteInput
              optionText='name'
              emptyText='clear search'
            />
          </ReferenceInput>
          <ReferenceInput
            source='levelId'
            reference='levels'
            sort={{
              field: 'name',
              order: 'ASC'
            }}
            filterToQuery={searchText => {
              return searchText && {
                'name[$regex]': searchText,
                'name[$options]': 'i'
              }
            }}
            allowEmpty
          >
            <AutocompleteInput
              optionText='name'
              emptyText='clear search'
            />
          </ReferenceInput>
          <ReferenceManyField label='Cele' reference='videoTargets' target='videoId'>
            <SingleFieldList>
              <ReferenceField reference='targets' source='targetId'>
                <ChipField source='name'/>
              </ReferenceField>
            </SingleFieldList>
          </ReferenceManyField>
          <Button variant='contained' onClick={() => setOpen(true)}>Edytuj Cele</Button>
          <Typography variant='caption'>Po edytowaniu celi odśwież stronę, żeby zobaczyć zmianę</Typography>
          <ChangeTargetsModal id={props.id} open={open} handleClose={() => setOpen(false)}/>
          <RichTextInput
            source='description'
          />
          <DateField
            source='createdAt'
            label='Created at'
            showTime
          />
          <DateField
            source='updatedAt'
            label='Updated at'
            showTime
          />
        </FormTab>
        <FormTab label='details'>
          <TextField
            source='wisitaHashId'
          />
          <TextInput
            source="sampleVideo"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const VideoList = (props) => (
  <List
    {...props}
    filters={<Filters/>}
  >
    <Datagrid>
      <TextField
        source='name'
      />
      <TextField
        source='wisitaHashId'
      />
      <TextField
        source='videoStatus'
      />
      <ReferenceField
        source="programId"
        reference="programs"
      >
        <TextField
          source='name'
        />
      </ReferenceField>
      <ReferenceField
        source="videoPodcastId"
        reference="videoPodcasts"
      >
        <TextField
          source='name'
        />
      </ReferenceField>
      <EditButton/>
    </Datagrid>
  </List>
)
